import { ReactNode, useEffect, useRef } from "react"
import styled from "styled-components"
import { text } from "../theme"

export interface Tab {
  id: string
  label: ReactNode
  onClick: () => void
}

interface Props {
  activeTabIndex: number
  tabs: Array<Tab>
}

const getTabId = (t: Tab) => `${t.id.replace(" ", "_")}_Tab`

export const TabCategoryMenu = ({ tabs, activeTabIndex }: Props) => {
  const scrollParentElement = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const activeTab = tabs[activeTabIndex]
    if (
      !activeTab ||
      !scrollParentElement.current ||
      typeof window === undefined
    )
      return
    // Do not use scrollIntoView, scrollIntoView will hijack the page scroll which
    // causes problems with the native id scrolling and will actually stop it
    const element = document.getElementById(getTabId(activeTab))
    if (!element) return
    const scrollLeft = scrollParentElement.current.scrollLeft
    const scrollWidth = scrollParentElement.current.clientWidth
    const elementPosition = element.offsetLeft
    const elementWidth = element.clientWidth
    const inFrameLeft = elementPosition > scrollLeft
    const inFrameRight =
      elementPosition + elementWidth < scrollLeft + scrollWidth
    if (!inFrameLeft || !inFrameRight) {
      scrollParentElement.current.scrollLeft = Math.max(
        elementPosition - scrollWidth / 2,
        0,
      )
    }
  }, [activeTabIndex])
  return (
    <TabContainer
      data-testid="TabCategoryMenu"
      role="tablist"
      ref={scrollParentElement}
    >
      {tabs.map((t, i) => (
        <TabElement
          id={getTabId(t)}
          data-testid="TabCategoryMenuElement"
          key={t.id}
          role="tab"
          aria-selected={i === activeTabIndex}
          $active={i === activeTabIndex}
          onClick={t.onClick}
        >
          {t.label}
        </TabElement>
      ))}
      <TabElementFiller />
    </TabContainer>
  )
}

const TabContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  width: 100%;

  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const TabElementFiller = styled.div`
  position: relative;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  display: flex;
  flex: 1;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 1;

    height: 2px;
    background: ${({ theme }) => theme.colors["Border/Secondary"]};
  }
`

const TabElement = styled.div<{ $active: boolean }>`
  position: relative;
  padding: 8px 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  ${({ theme, $active }) => `
    color: ${$active ? theme.colors["Content/Primary"] : "#555D6A;"};
  `}

  &:not(:last-of-type) {
    padding-right: 16px;
  }

  transition:
    border-color 250ms ease-in,
    color 250ms ease-in;

  ${text("Body/Regular")}
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 1;

    height: 2px;
    background: ${({ theme }) => theme.colors["Border/Secondary"]};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: calc(100% - 16px);
    z-index: 2;

    height: 2px;
    background: ${({ theme, $active }) =>
      $active ? theme.colors["Content/Primary"] : "transparent"};
  }

  &:last-of-type::after {
    width: 100%;
  }
`

import Link from "next/link"
import React, { MouseEvent } from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"
import {
  FaChevronDown,
  FaChevronRight,
  FaCheck,
  FaPlus,
  FaMinus,
  FaStar,
  FaSpinner,
  FaMapMarkerAlt,
} from "react-icons/fa"
import { GoArrowLeft } from "react-icons/go"
import {
  IoEllipsisVerticalSharp,
  IoPersonOutline,
  IoQrCode,
} from "react-icons/io5"
import {
  MdOutlineSupportAgent,
  MdOutlinePrivacyTip,
  MdAlternateEmail,
  MdOutlineTextsms,
} from "react-icons/md"
import { GoPaperAirplane } from "react-icons/go"
import { BsGear } from "react-icons/bs"
import { FiLogOut, FiTrash, FiPhone, FiMapPin } from "react-icons/fi"
import { LuWalletCards } from "react-icons/lu"
import styled from "styled-components"
import Account from "public/svgs/account.svg"
import Cart from "public/svgs/cart.svg"
import Gift from "public/svgs/gift.svg"
import Order from "public/svgs/order.svg"
import MenuSVG from "public/svgs/menu.svg"
import Search from "public/svgs/search.svg"
import Close from "public/svgs/close.svg"
import Visa from "public/wallet/Visa.svg"
import MasterCard from "public/wallet/Mastercard.svg"
import Amex from "public/wallet/Amex.svg"
import Discover from "public/wallet/Discover.svg"
import GooglePay from "public/wallet/GooglePay.svg"
import ApplePay from "public/wallet/ApplePay.svg"

import { CardBrand } from "@models/paymentMethod"

export enum IconKey {
  Account,
  ArrowLeft,
  Cart,
  Menu,
  Close,
  Map,
  Cog,
  Increment,
  Decrement,
  InfoCircle,
  ChevronDown,
  ChevronRight,
  Search,
  Checkmark,
  Gear,
  Profile,
  EllipsisVertical,
  Phone,
  Email,
  Preferences,
  Wallet,
  Logout,
  CustomerDelete,
  SupportAgent,
  Privacy,
  Star,
  SMS,
  Spinner,
  Address,
  QRCode,
  Gift,
  Order,
}

const mapKeyToIcon = (
  iconKey: IconKey | CardBrand,
  size: number,
  color: string,
): JSX.Element => {
  let Component
  switch (iconKey) {
    case IconKey.Account:
      Component = Account
      break
    case IconKey.ArrowLeft:
      Component = GoArrowLeft
      break
    case IconKey.Cart:
      Component = Cart
      break
    case IconKey.Menu:
      Component = MenuSVG
      break
    case IconKey.Close:
      Component = Close
      break
    case IconKey.Map:
      Component = FiMapPin
      break
    case IconKey.Increment:
      Component = FaPlus
      break
    case IconKey.Decrement:
      Component = FaMinus
      break
    case IconKey.InfoCircle:
      Component = AiOutlineInfoCircle
      break
    case IconKey.ChevronDown:
      Component = FaChevronDown
      break
    case IconKey.ChevronRight:
      Component = FaChevronRight
      break
    case IconKey.Search:
      Component = Search
      break
    case IconKey.Gift: {
      Component = Gift
      break
    }
    case IconKey.Cog:
      Component = BsGear
      break
    case IconKey.Checkmark:
      Component = FaCheck
      break
    case IconKey.Profile:
      Component = IoPersonOutline
      break
    case IconKey.EllipsisVertical:
      Component = IoEllipsisVerticalSharp
      break
    case IconKey.Phone:
      Component = FiPhone
      break
    case IconKey.Email:
      Component = MdAlternateEmail
      break
    case IconKey.Preferences:
      Component = GoPaperAirplane
      break
    case IconKey.Wallet:
      Component = LuWalletCards
      break
    case IconKey.Logout:
      Component = FiLogOut
      break
    case IconKey.CustomerDelete:
      Component = FiTrash
      break
    case IconKey.SupportAgent:
      Component = MdOutlineSupportAgent
      break
    case IconKey.Privacy:
      Component = MdOutlinePrivacyTip
      break
    case IconKey.Star:
      Component = FaStar
      break
    case IconKey.SMS:
      Component = MdOutlineTextsms
      break
    case IconKey.Order: {
      Component = Order
      break
    }
    case CardBrand.visa: {
      return <Visa />
    }
    case CardBrand.mastercard: {
      return <MasterCard />
    }
    case CardBrand.amex: {
      return <Amex />
    }
    case CardBrand.discover: {
      return <Discover />
    }
    case CardBrand.googlePay: {
      return <GooglePay />
    }
    case CardBrand.applePay: {
      return <ApplePay />
    }
    case CardBrand.unknown: {
      return <></>
    }
    case IconKey.Spinner:
      Component = FaSpinner
      break
    case IconKey.Address:
      Component = FaMapMarkerAlt
      break
    case IconKey.QRCode:
      Component = IoQrCode
      break
  }
  return (
    <Component
      size={size}
      height={size}
      width={size}
      stroke={color}
      color={color}
    />
  )
}

interface Props {
  iconKey: IconKey | CardBrand
  className?: string
  size?: number
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  to?: string
  color?: string
}

export const Icon = ({
  iconKey,
  className,
  onClick,
  to,
  size = 14,
  color = "#1C1C1C",
}: Props): JSX.Element => {
  const component = (
    <Container
      className={className}
      $interactive={Boolean(onClick ?? to)}
      onClick={onClick}
      $size={size}
    >
      {mapKeyToIcon(iconKey, size, color)}
    </Container>
  )

  if (to)
    return (
      <Link className={className} href={to}>
        {component}
      </Link>
    )
  return component
}

const Container = styled.div<{ $interactive: boolean; $size: number }>`
  ${({ $interactive }) =>
    $interactive ? "cursor: pointer;" : "cursor: inherit;"}
  ${({ $size }) => `
    width: ${$size}px;
    height: ${$size}px;
    max-width: ${$size}px;
    max-height: ${$size}px;
    min-width: ${$size}px;
    min-height: ${$size}px;
  `}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

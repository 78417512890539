import React, { useEffect, useMemo } from "react"
import { ReactNode } from "react"
import { useQuery } from "@tanstack/react-query"
import { DefaultTheme } from "styled-components"
import { get } from "lodash"
import { Loading, theme, ThemeColors } from "@openui"
import { toHexColor } from "@helpers/toHexColor"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { HOST } from "@constants/env"

export enum LoyaltyProgramType {
  REWARD_POINTS = "REWARD_POINTS",
  ACCUMULATION = "ACCUMULATION",
}

export interface MerchantConfig {
  merchantId: number
  displayName: string
  brandColor: string
  brandTextColor: string
  backgroundColor?: string | null
  logoUrl: string
  iconUrl: string
  bundleName: string
  mapMarkerUrl: string
  itemPlaceholderUrl: string
  webLogoUrl: string
  fallbackOrderUrl: string | null
  privacyUrl: string
  rewardEnabled: boolean
  inStoreRewardEnabled: boolean
  inStoreGiftCardEnabled: boolean
  loyaltyProgramType: LoyaltyProgramType
}

export interface MerchantConfigContext {
  theme: DefaultTheme
  config: MerchantConfig | null
}

export const MerchantConfigContext = React.createContext<MerchantConfigContext>(
  {
    theme: theme,
    config: null,
  },
)

export const getLoadMerchantConfigQuery = (defaultStore?: string) => {
  const domain = HOST.get()
  const configApiUrl = `merchants/${domain}/load-merchant/${defaultStore === undefined ? "" : "?store_id=" + defaultStore}`
  return {
    queryKey: [QUERY_KEYS.GET_MERCHANT_CONFIG, configApiUrl],
    queryFn: async () => {
      const response = await apiClient.get<{
        merchant: { merchant_id: number; [key: string]: unknown }
        stores: Array<{ [key: string]: unknown }>
        active_store: { id: number; [key: string]: unknown }
        active_menu: {
          id: number
          menu_categories: Array<{
            menu_items: Array<{
              id: number
              popularity_score: null | number
            }>
          }>
          [key: string]: unknown
        }
        active_new_customer_promos: { [key: string]: unknown }
      }>(configApiUrl)
      if (response && response?.body !== null) {
        return response.body
      }
      return null
    },
  }
}

export const getMerchantConfigQuery = () => {
  const domain = HOST.get()
  const configApiUrl = `merchants/${domain}/app-settings/`
  return {
    queryKey: [QUERY_KEYS.GET_MERCHANT_CONFIG, configApiUrl],
    queryFn: async () => {
      const response = await apiClient.get<{ [key: string]: unknown }>(
        configApiUrl,
      )
      if (response && response?.body !== null) {
        return {
          merchantId: response.body["merchant_id"] as number,
          displayName: response.body["display_name"] as string,
          brandColor: response.body["brand_color"] as string,
          brandTextColor: response.body["brand_text_color"] as string,
          logoUrl: response.body["logo_url"] as string,
          iconUrl: response.body["icon_url"] as string,
          backgroundColor:
            (response.body["background_color"] as string | undefined | null) ??
            null,
          bundleName: response.body["bundle_name"] as string,
          mapMarkerUrl: response.body["map_marker_url"] as string,
          itemPlaceholderUrl:
            (response.body["item_placeholder_url"] as string | undefined) ??
            "/item_placeholder.png",
          webLogoUrl: response.body["web_logo_url"] as string,
          fallbackOrderUrl: response.body["fallback_order_url"] as string,
          privacyUrl: ("https://getopen.com/privacy-policies/" +
            response.body["bundle_name"]) as string,
          rewardEnabled: response.body["reward_enabled"] as boolean,
          inStoreRewardEnabled: response.body[
            "in_store_reward_enabled"
          ] as boolean,
          inStoreGiftCardEnabled: response.body[
            "in_store_gift_card_enabled"
          ] as boolean,
          loyaltyProgramType: response.body[
            "loyalty_program_type"
          ] as LoyaltyProgramType,
        }
      }
      return null
    },
  }
}

type RawMerchantConfig = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getMerchantConfigQuery>["queryFn"]>>
>

const keyToFieldName: Partial<Record<ThemeColors, keyof RawMerchantConfig>> = {
  "Background/Brand": "brandColor",
  "Background/Primary": "backgroundColor",
  "Content/Brand": "brandTextColor",
}

interface Props {
  children: ReactNode | ReactNode[]
}

export const MerchantConfigProvider = ({ children }: Props) => {
  const config = useQuery(getMerchantConfigQuery())

  const brandedTheme = useMemo(() => {
    return {
      ...theme,
      colors: Object.keys(theme.colors).reduce((acc, key) => {
        const keyValue = keyToFieldName[key as ThemeColors]
        return {
          ...acc,
          [key]:
            keyValue && get(config.data ?? {}, keyValue)
              ? toHexColor(get(config.data, keyValue)?.toString() ?? "")
              : theme.colors[key as ThemeColors],
        }
      }, theme.colors),
    }
  }, [config.data?.brandColor, config.data?.brandTextColor])

  useEffect(() => {
    if (typeof window !== "undefined" && config.data?.iconUrl) {
      const favicon = window.document.createElement("link")
      favicon.setAttribute("href", config.data.mapMarkerUrl)
      favicon.setAttribute("rel", "icon")
      favicon.setAttribute("type", "image/png")
      document.head.append(favicon)
    }
  }, [config.data?.iconUrl])

  if (!config.data) return <Loading />

  return (
    <MerchantConfigContext.Provider
      value={{
        theme: brandedTheme,
        config: config.data ?? null,
      }}
    >
      {children}
    </MerchantConfigContext.Provider>
  )
}
